import React, { useEffect } from "react";
import _ from "lodash";
import "leaflet/dist/leaflet.css";
import "./map.css";
import L, { LatLngTuple } from "leaflet";
import { GetWeatherQuery, WeatherDataFragment } from "../__generated__/graphql";
import {
  polygon,
  getCoord,
  getCoords,
  bbox,
  center,
  bboxPolygon,
  points,
} from "@turf/turf";
import { renderToStaticMarkup } from "react-dom/server";
import {
  getRiskIndex,
  getTime,
  sanitizeMessage,
  WeatherRiskInput,
} from "alhue-common";
import { useNavigate } from "react-router-dom";

function decodePolygon(polygonSerialized: string): [][] {
  const data = JSON.parse(polygonSerialized) as number[][][];
  const output = [];
  for (let i = 0; i < data[0].length; i++) {
    output.push([data[0][i][1], data[0][i][0]]);
  }
  return output as unknown as [][];
}

function getStationForCommunity(community: string) {
  const l = community.toLowerCase();
  if (l.includes("patag")) {
    return "pataguilla";
  }
  if (l.includes("zapata") || l.includes("guila")) {
    return "curacavi";
  }
  return "fundoAlhue";
}

function weatherMessageForStation(s: WeatherDataFragment) {
  return (
    <>
      <h3>Estación {s.station.name}</h3>
      <ul>
        <li>Temperatura: {s.tempc} °C</li>
        <ul>
          <li>
            Mínima hoy: {s.min_daily_tempc} °C {s.time_min_daily_tempc}
          </li>
          <li>
            Máxima hoy: {s.max_daily_tempc} °C {s.time_max_daily_tempc}
          </li>
        </ul>
        <li>Humedad Ambiental: {s.humidity} %</li>
        <li>Ráfagas de viento: {Math.round(s.windgustkph)} km/h</li>
        {s.max_daily_windgustkph && (
          <ul>
            <li>
              Máxima hoy: {Math.round(s.max_daily_windgustkph)} km/h
              {s.time_max_daily_windgustkph}
            </li>
          </ul>
        )}
        {s.uv_message && (
          <li>
            Indice UV: {s.uv} ({s.uv_message})
          </li>
        )}
        {s.PM2_5Density && (
          <li>
            Calidad del Aire PM2.5: {Math.round(s.PM2_5Density)}µg/m³ (
            {s.PM2_5Density_message})
          </li>
        )}
      </ul>
    </>
  );
}

export default function HeatMap({
  data,
  marker,
}: {
  data: GetWeatherQuery;
  marker?: string;
}) {
  const navigate = useNavigate();
  useEffect(() => {
    const communities = data.getPublicCommunities;
    if (!communities) {
      return;
    }
    const bomberos = data.getEmergenciasBomberos;
    const stations: WeatherDataFragment[] = _.filter(
      [data.curacavi, data.fundoAlhue, data.pataguilla],
      (x) => {
        return !!x;
      },
    ) as WeatherDataFragment[];

    const coords = _.chain(communities)
      .map((c) => {
        const polygonArray = decodePolygon(c.polygonSerialized);
        const b = polygon([polygonArray]);
        return getCoords(b);
      })
      .flattenDepth(2)
      .value();
    const boundingBox = bboxPolygon(bbox(points(coords)));
    const centerView = center(boundingBox);

    // // Initialize Leaflet map
    const map = L.map("map").setView(getCoord(centerView) as LatLngTuple, 11);

    // Add a tile layer to the map (you can choose any tile layer provider)
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      subdomains: ["a", "b", "c"],
      attribution: `&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | B9 Ingeniería SpA`,
      noWrap: true,
    }).addTo(map);

    for (const c of communities) {
      const communityPolygon = polygon([decodePolygon(c.polygonSerialized)]);
      // const name = c.name.replace(/ Emergenci.*/, "");
      const station = getStationForCommunity(c.name);
      const weather = data[station];
      // const center = getCoord(centerOfMass(communityPolygon));
      if (weather) {
        const color = getRiskIndex(weather as WeatherRiskInput).color;
        new L.Polygon(getCoords(communityPolygon))
          .setStyle({ color: color, weight: 1, fillOpacity: 0.2 })
          .addTo(map);

        // new L.Marker({ lat: center[0], lng: center[1] })
        //   .setIcon(
        //     new L.DivIcon({
        //       className: "",
        //       html: `<div class="comunidadName">${name}</div>`,
        //       iconSize: [65, 65],
        //     }),
        //   )
        //   .addTo(map);
      }
    }
    for (const s of stations) {
      if (s) {
        const color = getRiskIndex(s).color;
        const latlng = {
          lat: s.station.location.latitude,
          lng: s.station.location.longitude,
        };
        const estacion = new L.Marker(latlng)
          .setIcon(
            new L.DivIcon({
              className: "",
              iconSize: [100, 100],
              popupAnchor: [-10, -30],
              html: `<div class="textMarker" style="background: ${color}">${s.tempc} °C</div>`,
            }),
          )
          .addTo(map)
          .on("click", () => {
            if (s.station.name) {
              navigate(`/w/${s.station.name.replace(/ /, "_")}`);
            }
          });

        if (marker && s.station.name === marker.replace(/_/, " ")) {
          estacion
            .bindPopup(() => {
              return renderToStaticMarkup(weatherMessageForStation(s));
            })
            .openPopup();
          map.setView(latlng);
        }
      }
    }

    for (const b of bomberos) {
      const message = getTime(b.timestamp) + sanitizeMessage(b.message);
      if (b.location) {
        const latlng = {
          lat: b.location.latitude,
          lng: b.location.longitude,
        };

        const emergency = new L.Marker(latlng)
          .setIcon(
            new L.DivIcon({
              className: "bomberos",
              html: `🚒`,
            }),
          )
          .addTo(map)
          .bindPopup(() => {
            return message;
          });
        if (b.id === marker) {
          emergency.openPopup();
          map.setView(latlng);
        }
      }
    }

    // Clean up the map on component unmount
    return () => {
      map.remove();
    };
  }, [data, marker, navigate]);

  return <div style={{ height: "80vh" }} id="map"></div>;
}
