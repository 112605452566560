import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import HeatMap from "./heatmap/HeatMap";
import { useQuery } from "@apollo/client";
import { GET_DATA } from "./graphql/queries";
//import _ from "lodash";
//import TempGraph from "./graphs/TempGraph";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

function App() {
  const { marker } = useParams();
  const location = useLocation();
  const { data, error } = useQuery(GET_DATA, {
    variables: {
      station1: "Curacaví Aeródromo",
      station2: "Fundo Alhué",
      station3: "Patagüilla",
    },
    errorPolicy: "all",
    pollInterval: 1000 * 60 * 5,
  });

  if (error) {
    console.error(error);
  }

  if (!data || !location) {
    if (error) {
      return (
        <Container>
          <Grid>Error al recuperar datos de meteorología</Grid>
        </Container>
      );
    }
    return (
      <Container>
        <Grid>
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  //const showGraph = _.startsWith(location.pathname, "/w/") && marker;
  return (
    <Box height="80vh" display="flex" flexDirection="column">
      <HeatMap data={data} marker={marker} />
      {/* {showGraph && <TempGraph station={marker} />} */}
    </Box>
  );
}

export default App;
