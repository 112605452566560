export const LAGUNA_DE_ACULEO = "Laguna de Aculeo";
export const LO_PINTO = "Lo Pinto";
export const CERRO_SAN_FRANCISCO = "Cerro San Francisco";
export const LLANOS_DE_CALEU = "Llanos de Caleu";
export const SAN_JOSE_GUAYACAN = "San José Guayacán";
export const EL_COLORADO = "El Colorado";
export const POPULO_ALHUE = "Populo Alhué";
export const EURAILIS_PAINE = "Euralis Chile";
export const PRINCIPAL_PIRQUE = "Pirque Rinconada del Principal";

export const ESTACIONES_METEOROLOGICAS = [
  "Fundo Alhué",
  "Curacaví Aeródromo",
  "Patagüilla",
  "Pudahuel Santiago",
  LAGUNA_DE_ACULEO,
  LO_PINTO,
  CERRO_SAN_FRANCISCO,
  LLANOS_DE_CALEU,
  SAN_JOSE_GUAYACAN,
  EL_COLORADO,
  POPULO_ALHUE,
  EURAILIS_PAINE,
  PRINCIPAL_PIRQUE,
];

export const ESTACIONES_COMUNIDAD_CONTRA_EL_FUEGO = [
  LAGUNA_DE_ACULEO,
  LO_PINTO,
  CERRO_SAN_FRANCISCO,
  LLANOS_DE_CALEU,
  SAN_JOSE_GUAYACAN,
  EL_COLORADO,
  POPULO_ALHUE,
  EURAILIS_PAINE,
  PRINCIPAL_PIRQUE,
];
