/**
 * identificador especial para un mensaje que contiene una tarea para el chatbot
 */
export const TARGET_CHATBOT = "CHATBOT";

export const BOTS = ["12185031337@c.us", "19049159315@c.us"];
export const MANUEL_CHAT_ID = "56979449304@c.us";
export const CONTRAFUEGO_ALFA = "120363191924096591@g.us";
// export const DEV_PUBLIC_GROUP_ID = "120363179038814879@g.us";
export const BETA_1_GROUP_ID = "120363128142123149@g.us";
// export const BETA_2_GROUP_ID = "120363204347032421@g.us";
export const BETA_3_GROUP_ID = "120363229576319737@g.us";
export const BOLETIN_GROUP_ID = "120363207213132102@g.us";
export const BEAUCHEF_GROUP_ID = "120363247095306417@g.us";
export const CONTRAELFUEGO_CURACAVI_GROUP_ID = "120363220881965965@g.us";

//grupos de emergencia curacavi
export const PREVENCION_RIESGOS_GROUP_ID = "56976680224-1544968718@g.us";
export const ALHUE_EMERGENCIAS_GROUP_ID = "120363161850587182@g.us";
export const EL_TORO_EMERGENCIAS_GROUP_ID = "56976680224-1633094309@g.us";
export const ZAPATA_EMERGENCIAS_GROUP_ID = "120363024681120213@g.us";
export const LO_AGUILA_EMERGENCIAS_GROUP_ID = "120363171321780835@g.us";
export const PATAGUILLAS_EMERGENCIAS_GROUP_ID = "56954314016-1439085084@g.us";

//Cantillana
export const BATUCO_EMERGENCIAS_GROUP_ID = "120363304327125589@g.us";
export const LA_PLATA_EMERGENCIAS_GROUP_ID = "120363364539110404@g.us";
export const EL_ROBLE_EMERGENCIAS_GROUP_ID = "120363344842381565@g.us";
export const LAS_ANIMAS_EMERGENCIAS_GROUP_ID = "120363364118557620@g.us";
export const LAGUNILLAS_EMERGENCIAS_GROUP_ID = "120363346127870048@g.us";
export const EL_AJIAL_EMERGENCIAS_GROUP_ID = "120363347037720139@g.us";
export const PICHE_EMERGENCIAS_GROUP_ID = "120363348235710499@g.us";
export const TORCAZAS_EMERGENCIAS_GROUP_ID = "120363369482929897@g.us";
export const NOGALES_EMERGENCIAS_GROUP_ID = "120363369495704445@g.us";
export const YERBA_LOCA_EMERGENCIAS_GROUP_ID = "120363353540528524@g.us";

// grupos emergencia paine
export const ACULEO_EMERGENCIAS_GROUP_ID = "120363290898929741@g.us";
export const CONTRAELFUEGO_SN_GROUP_ID = "120363326841851384@g.us";

//comunidades
export const FUNDO_ALHUE_GROUP_ID = "56998956218-1431874594@g.us";
export const FUNDO_ALHUE_EMERGENCIAS_GROUP_ID = "120363163144781006@g.us";
export const RUCALHUE_GROUP_ID = "56994334783-1448419247@g.us";

//hermanos
export const HERMANOS_GROUP_ID = "120363188475414567@g.us";

export const getTestChatId = () => {
  if (process.env.ENVIRONMENT === "development") {
    return CONTRAFUEGO_ALFA;
  }
  if (process.env.ENVIRONMENT === "beta") {
    return BETA_3_GROUP_ID;
  }
  throw new Error("Missing environment unknown test chat Id");
};
