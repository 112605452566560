import { event } from "@pagerduty/pdjs";

export async function sendPagerAlert(
  dedup: string,
  message: string,
  source: string,
) {
  console.error("pager:", message);
  if (process.env.CI || process.env.JEST_WORKER_ID) {
    console.log("don't trigger pager during tests");
    return;
  }
  const summary =
    process.env.ENVIRONMENT === "development" ? "[DEV]" + message : message;
  try {
    await event({
      data: {
        routing_key: "0e1f42891a1b430fd055dd966d23cbed",
        event_action: "trigger",
        dedup_key: source + dedup + process.env.ENVIRONMENT,
        payload: {
          summary,
          source: source,
          severity: "error",
          custom_details: {
            environment: process.env.ENVIRONMENT,
          },
        },
      },
    });
  } catch (e) {
    console.error("Error sending message to pager", e);
  }
}
