import { gql } from "@apollo/client";

export const GET_DATA = gql(`
query getWeather($station1: String!, $station2: String!, $station3: String!) {
  curacavi: getWeather(station: $station1) {
    ...weatherData
  }
  fundoAlhue: getWeather(station: $station2) {
    ...weatherData
  }
  pataguilla: getWeather(station: $station3) {
    ...weatherData
  }
  getPublicCommunities {
    name
    polygonSerialized
  }
  getEmergenciasBomberos {
    id
    timestamp
    message
    location {
      latitude
      longitude
    }
  }
}
fragment weatherData on Weather {
  station {
    name
    location {
      latitude
      longitude
    }
  }
  tempc
  humidity
  uv
  uv_message
  windgustkph
  PM2_5Density
  PM2_5Density_message
  max_daily_tempc
  min_daily_tempc
  max_daily_windgustkph
  time_max_daily_tempc
  time_min_daily_tempc
  time_max_daily_windgustkph
  hourlyrainmm
  dailyrainmm
  yearlyrainmm
  eventrainmm
}
`);

export const GET_DAILY_STATION = gql(`
query getDailyWeather($station: String!) {
    getDailyWeather(station: $station) {
        timestamp
        tempc
        station {
            name
          }
    }
  }`);
