import { DateTime } from "luxon";

const humanize = require("humanize-duration");

export function elapsedMillisecondsToHuman(elapsed: number) {
  return humanize(elapsed, {
    largest: 2,
    round: true,
    language: "es",
    serialComma: false,
    conjunction: " y ",
  });
}

export function getCarEmoji() {
  const cars = ["🚘", "🚗", "🚙", "🚙", "🚚"];
  return cars[Math.floor(Math.random() * cars.length)];
}

export const getTime = (timestamp: string) => {
  const today = DateTime.now().setZone("America/Santiago");
  const event = DateTime.fromISO(timestamp).setZone("America/Santiago");
  if (today.hasSame(event, "day")) {
    return event.toFormat("HH:mm");
  } else {
    return event.toFormat("dd-MM-yyyy HH:mm");
  }
};

export function sanitizeMessage(message: string) {
  // Regular expression to match Google Maps URLs
  const googleMapsRegex =
    / https?:\/\/(?:www\.)?google\.[a-z.]+\/maps[^\s]*| https?:\/\/maps\.google\.[a-z.]+\/maps[^\s]*/gi;

  const curacaviEmergenciaRegex =
    / https?:\/\/[a-z\-]+\.b9\.cl\/emergencias\/[^\s]*/g;

  return message
    .replace(/\*Emergencia 🚒\*/, "")
    .replace(googleMapsRegex, "")
    .replace(curacaviEmergenciaRegex, "")
    .replace(/ {2}/g, " ")
    .replace(/\n\n/g, "\n")
    .trim();
}
