const queries = [
  "getActiveCommunityInvitation",
  "getCommunity",
  "getNativeUser",
  "listCommunities",
  "getInvitationByCode",
  "getPendingJoinRequest",
] as const;

const mutations = [
  "joinToCommunity",
  "saveToken",
  "deleteToken",
  "createNativeCommunity",
  "createCommunityInvitation",
  "deleteCommunityMember",
  "updateNativeUser",
  "selfDeleteNativeUser",
  "respondJoinRequest",
  "triggerEmergency",
  "sendMessage",
] as const;

export type GraphqlQueries = (typeof queries)[number];
export type GraphqlMutations = (typeof mutations)[number];

export const GraphqlOperations = {
  queries,
  mutations,
};
