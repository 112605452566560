import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./ErrorPage";

/**
 * Rutas deben estar declaradas también en `backend/cloudfront/url-rewrite.js`
 * @constructor
 */
export default function AppRoutes() {
  const router = createBrowserRouter([
    {
      path: "/w/:marker",
      element: <App />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/b/:marker",
      element: <App />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
    },
  ]);
  return <RouterProvider router={router} />;
}
